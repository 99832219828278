import styled from "styled-components";
import img from "../assets/images/blocks.jfif";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--dark-grey);
  // background-image: url(${img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  // transform: translateY(-50%);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const BottomContainer = styled.div`
  display: flex;
  position: relative;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 70%;
  // transform: translateY(-30%);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const GalleryContainer = styled.div`
  display: flex;
  position: relative;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  // transform: translateY(-30%);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--white);
  font-size: .6rem;
  font-weight: 500;
  line-height: 1.6;
  font-family: "Argentum";
  @media only screen and (max-width: 40em) { 
    font-size: .6rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   

`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 1.2rem;
  line-height: 1.6;
  font-family: "Argentum";

  
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: 1.5rem;
  text-align: right;
  padding-top: 0%;
  padding-left: 0%;
  margin-top: 4%;
  // color: #ff2419;
  // padding-bottom: 2%;
  // line-height: 1.6;
  // font-family: "JetRegular";
  letter-spacing: .04em;

  @media all and (max-width: 480px) { 
    margin-top: 5%;
    // background: red;
  }

  @media only screen and (max-width: 40em) { 
    font-size: .5rem;
    margin-top: 3.5%;
   } /* max-width 640px, mobile-only styles, use when QAing mobile issues */

   @media only screen and (min-width: 40.063em) and (max-width: 64em) {
      font-size: .9rem;
    } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    font-size: 1.1rem;
    margin-top: 3%;
    // color: red;
    } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em)  { 
    font-size: 1.1rem;
    margin-top: 3%;
    } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

    @media only screen and (min-width: 120.063em) {
      font-size: 1.5rem;
      margin-top: 2.5%;
     } 


  `;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const TextHeader = styled.p`
font-size: 3rem;
line-height: 1;
letter-spacing: -.06em;
position: relative;
color: white;
font-family: "JetMedium";
font-weight: 200;
// margin-top: -2%;
padding: 2% 2%;
// display: flex;
// justify-content: flex-start;
// flex-direction: column;
// align-items: flex-start;
// align-content: flex-start;
width: 100%;
// height: 100%;
// background-color: white;
// transform: translateY(-15%);
// border-top: 2px solid #D8D8D8;
// border-bottom: 2px solid #D8D8D8;

@media only screen and (max-width: 40em) { 
  font-size: 1.1rem;
  line-height: 1;
  // padding-bottom: 8%;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

@media all and (min-width: 480px) and (max-width: 768px) { 
    font-size: 2.5rem;
    padding: 2% 2%;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) {
    padding: 2.5% 1%;
   } /* min-width 1025px, large screens */

@media only screen and (min-width: 90.063em)  { 
  font-size: 4rem;
  } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */
  
text-shadow: 1px 2px 3px #DDEEFF,
0 0 0 black,
-1px 1px 0 #DDEEFF,
// 1px 1px 0 white;

// color: rgba(254, 103, 2, .8);

&:hover {
  &:before {
      animation: glitch .25s cubic-bezier(.25, .46, .45, .94) both infinite
  }
  &:after {
      animation: glitch .25s cubic-bezier(.25, .46, .45, .94) reverse both infinite
  }
}
*/
}
@keyframes glitch {
0% {
    transform: translate(-5px, 5px)
}
30% {
    transform: translate(-5px, -5px)
}
55% {
    transform: translate(5px, 5px)
}
80% {
    transform: translate(5px, -5px)
}
}
`;

  export const TextHeaderSub = styled.p`
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: -.06em;
  position: relative;
  color: white;
  font-family: "JetMedium";
  font-weight: 200;
  // margin-top: -2%;
  padding: 2% 2%;
  // display: flex;
  // justify-content: flex-start;
  // flex-direction: column;
  // align-items: flex-start;
  // align-content: flex-start;
  width: 100%;
  // height: 100%;
  // background-color: white;
  // transform: translateY(-15%);
  // border-top: 2px solid #D8D8D8;
  // border-bottom: 2px solid #D8D8D8;
  
  @media only screen and (max-width: 40em) { 
    font-size: 1.1rem;
    line-height: 1;
    // padding-bottom: 8%;
    } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 
  
  
  @media only screen and (min-width: 64.063em) {
      padding: 2.5% 1%;
     } /* min-width 1025px, large screens */
  
  @media only screen and (min-width: 90.063em)  { 
    font-size: 2.3rem;
    } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */
    
  text-shadow: 1px 2px 3px #DDEEFF,
  0 0 0 black,
  -1px 1px 0 #DDEEFF,
  // 1px 1px 0 white;
  
  // color: rgba(254, 103, 2, .8);

  `;

  export const TextHeaderDescription = styled.p`
  font-size: 1.7rem;
  line-height: 1;
  letter-spacing: -.06em;
  position: relative;
  color: white;
  font-family: "JetMedium";
  font-weight: 200;
  // margin-top: -2%;
  padding: 2% 2%;
  // display: flex;
  // justify-content: flex-start;
  // flex-direction: column;
  // align-items: flex-start;
  // align-content: flex-start;
  width: 100%;
  // height: 100%;
  // background-color: white;
  // transform: translateY(-15%);
  // border-top: 2px solid #D8D8D8;
  // border-bottom: 2px solid #D8D8D8;
  
  @media only screen and (max-width: 40em) { 
    font-size: .7rem;
    line-height: 1.2;
    // padding-bottom: 8%;
    // background: orange;
    } 
  

    @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
      // background: gray;
       font-size: 1.3rem;
       line-height: 1.2;
        // margin-top: -2%;
    }
    
  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
          // padding: 2.5% 1%;
          font-size: 1.4rem;
          line-height: 1.2;

        // background: blue;    
        } /* min-width 1025px, large screens */
    
  @media only screen and (min-width: 90.063em) { 
    line-height: 1.2;

          // background: yellow;
          font-size: 1.5rem;
        } /* min-width 1441px, xlarge screens */
    
 @media only screen and (min-width: 120.063em) { 
          font-size: 1.6rem;
          line-height: 1.2;

          // background: red;
        }


  `;

  export const TextHeaderMB = styled.p`
  // line-height: .75;
  letter-spacing: .01em;
  color: #ffffff;
  // border-bottom: 10px dotted;
  padding: 0;
  font-size: 6rem;
  // margin-top: 10rem;
  // transform: translateY(-15%);
  animation: glitch1 15.5s infinite;
  line-height: 0;
  :nth-child(2) {
    color: #67f3da;
    animation: glitch2 15.5s infinite;
  }
  
  :nth-child(3) {
    color: #f16f6f;
    animation: glitch3 15.5s infinite;
  }
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
    7% {
      transform: skew(-0.5deg, -0.7deg);
      opacity: 0.75;
    }
    10% {
      transform: none;
      opacity: 1;
    }
    27% {
      transform: none;
      opacity: 1;
    }
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
    35% {
      transform: none;
      opacity: 1;
    }
    52% {
      transform: none;
      opacity: 1;
    }
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
    50% {
      transform: none;
      opacity: 1;
    }
    72% {
      transform: none;
      opacity: 1;
    }
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
    80% {
      transform: none;
      opacity: 1;
    }
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
    10% {
      transform: none;
      opacity: 0.25;
    }
    27% {
      transform: none;
      opacity: 0.25;
    }
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
    35% {
      transform: none;
      opacity: 0.25;
    }
    52% {
      transform: none;
      opacity: 0.25;
    }
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
    50% {
      transform: none;
      opacity: 0.25;
    }
    72% {
      transform: none;
      opacity: 0.25;
    }
    75% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
    80% {
      transform: none;
      opacity: 0.25;
    }
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
    10% {
      transform: none;
      opacity: 0.25;
    }
    27% {
      transform: none;
      opacity: 0.25;
    }
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
    35% {
      transform: none;
      opacity: 0.25;
    }
    52% {
      transform: none;
      opacity: 0.25;
    }
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
    50% {
      transform: none;
      opacity: 0.25;
    }
    72% {
      transform: none;
      opacity: 0.25;
    }
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
    80% {
      transform: none;
      opacity: 0.25;
    }
    100% {
      transform: none;
      opacity: 0.25;
    }
  }

  @media only screen and (max-width: 40em) { 
    font-size: 2rem;
    } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   

   @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
        font-size: 2.9rem;
        
    } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

    @media only screen and (min-width: 64.063em) and (max-width: 90em) { 
      font-size: 3.4rem;
    }

    @media only screen and (min-width: 90.063em) and (max-width: 120em) {
      font-size: 3.5rem;
    }

    @media only screen and (min-width: 120.063em) { 
      font-size: 4rem;
    }

         color: #D0D3D4;
         text-shadow:
           0 1px 0px #283747,
           1px 0 0px #283747,
           1px 2px 1px #283747,
           2px 1px 1px #283747,
           2px 3px 2px #283747,
           3px 2px 2px #283747,
           3px 4px 2px #283747,
           4px 3px 3px #283747,
           4px 5px 3px #283747,
           5px 4px 2px #283747,
           5px 6px 2px #283747,
           6px 5px 2px #283747,
          //  6px 6px 1px #283747,
          //  6px 6px 1px #283747,
          //  6px 7px 0px #283747,
          //  6px 7px 0px #283747;
         letter-spacing:1px;
         font-weight: bold;
       
  // text-shadow: 1px 1px 0 #809bce   ,
  // 2px 2px 0 #95b8d1 ,
  // 3px 3px 0 #b8e0d2 ,
  // 4px 4px 0 #a7bed3  ;
  // background-image: -webkit-linear-gradient(92deg, #d1cfe2 , #52b2cf );
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	// -webkit-animation: hue 10s infinite ease-in-out;

  // @keyframes hue {
  //   from {
  //     -webkit-filter: hue-rotate(0deg);
  //   }
  //   to {
  //     -webkit-filter: hue-rotate(-360deg);
  //   }

  `;

  export const TextButton = styled.p`
  line-height: 1;
  letter-spacing: .15em;
  color: #fffff;
  font-size: 2.7rem;
  
  @media only screen and (max-width: 40em) { 
    font-size: .6rem;
    line-height: 1;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

  @media only screen and (min-width: 40.063em) and (max-width: 64em) {
    font-size: 1.3rem;
    line-height: 1;
  }

  @media only screen and (min-width: 64.063em) and (max-width: 90em)  {
    font-size: 1.5rem;
    line-height: 1;
  }

  @media only screen and (min-width: 90.063em) and (max-width: 120em) { 
    font-size: 1.5rem;
    line-height: 1;
  }

  @media only screen and (min-width: 120.063em) {
    font-size: 1.7rem;
    line-height: 1;
   } /* min-width 1921px, xlarge screens */
  
	// background-image: -webkit-linear-gradient(92deg, #8510d8, #f35626);
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	// -webkit-animation: hue 1s infinite ease-in-out;
  // @keyframes hue {
  //   from {
  //     -webkit-filter: hue-rotate(0deg);
  //   }
  //   to {
  //     -webkit-filter: hue-rotate(-360deg);
  //   }
  // }
  
  `;

  export const TextSubHead = styled.p`
  font-size: 1.6rem;
  line-height: 1.1;
  font-weight: 200;
  letter-spacing: .05em;
  position: relative;
  color: white;

  // min-width: 0;
  // border-top: 2px solid #D8D8D8;
  // border-bottom: 2px solid #D8D8D8;
  // border-left: 2px solid white;

  padding: 4% 4%;
    width: 100%;
  // transform: translateY(-15%);
  text-align: left;

  @media only screen and (max-width: 40em) { 
    border-left: none;
    font-size: .8rem;
    line-height: 1.1;
    // padding-bottom: 8%;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    font-size: 1rem;
    line-height: 1.2;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */     
  
  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    font-size: 2rem;
    line-height: 1.1;
    // background-color: white;
  } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
    font-size: 2.5rem;
    line-height: 1;
   } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) { 
    font-size: 2.2rem;
    line-height: 1.2;
   } /* min-width 1921px, xlarge screens */

        
 text-shadow: 1px 2px 3px #DDEEFF,
  0 0 0 black,
  -1px 1px 0 #DDEEFF,
  // 1px 1px 0 white;

// color: rgba(254, 103, 2, .8);
  
  &:hover {
    &:before {
        animation: glitch .25s cubic-bezier(.25, .46, .45, .94) both infinite
    }
    &:after {
        animation: glitch .25s cubic-bezier(.25, .46, .45, .94) reverse both infinite
    }
  }
  */
  }

  @keyframes glitch {
  0% {
      transform: translate(-5px, 5px)
  }
  30% {
      transform: translate(-5px, -5px)
  }
  55% {
      transform: translate(5px, 5px)
  }
  80% {
      transform: translate(5px, -5px)
  }
  }
  
  `;

  export const TextDetails = styled.p`
  font-size: .9rem;
  line-height: 1.2;
  width: 100%;
  font-family: "JetThin";
  letter-spacing: .02em;
  position: relative;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  min-width: 0;
  // border-top: 2px solid #32323c;
  // padding-top: 2%;
  // border-bottom: 2px solid #D8D8D8;
  // border-left: 2px solid white;

  @media only screen and (max-width: 40em) { 
    font-size: .4rem;
    line-height: 1.1;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

  @media only screen and (min-width: 40.063em) and (max-width: 64em)  { 
    font-size: .7rem;
    // background-color: white;
    }

    @media only screen and (min-width: 64.063em) and (max-width: 90em) { 
    font-size: 1rem;
    }
  
    @media only screen and (min-width: 90.063em) and (max-width: 120em)  { 
      font-size: 1.1rem;
      } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

      @media only screen and (min-width: 120.063em) { 
        font-size: 1.1rem;
        width: 85%;


      }
`;

  export const TextSummary = styled.p`
  font-size: 1.1rem;
  line-height: 1.5;
  align-items: center;
  letter-spacing: -.06em;
  position: relative;
  color: white;
  padding: 3% 3%;
  width: 100%;
  max-height: auto;
  // border-top: 2px solid #D8D8D8;
  // border-bottom: 2px solid #D8D8D8;
  // border-right: 2px solid #4a4a4a;
  // transform: translateY(-15%);

  @media only screen and (max-width: 40em) { 
    font-size: .75rem;
    line-height: 1.3;
    border-right: none;
    // background: red;
    // padding-bottom: 8%;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    font-size: 1.1rem;
    line-height: 1.3;
    // background-color: green;

    } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
          font-size: 1.5rem;
          // background-color: white;
    } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em)  { 
    font-size: 1.7rem;
    // background-color: white;
    } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

  @media only screen and (min-width: 120.063em) { 
  font-size: 1.9rem;
  } /* min-width 1921px, xlarge screens */

  text-shadow: 1px 2px 3px #DDEEFF,
  0 0 0 black,
  -1px 1px 0 #DDEEFF,
  // 1px 1px 0 white;

// color: rgba(254, 103, 2, .8);
  
  &:hover {
    &:before {
        animation: glitch .25s cubic-bezier(.25, .46, .45, .94) both infinite
    }
    &:after {
        animation: glitch .25s cubic-bezier(.25, .46, .45, .94) reverse both infinite
    }
  }
  */
  }

  @keyframes glitch {
  0% {
      transform: translate(-5px, 5px)
  }
  30% {
      transform: translate(-5px, -5px)
  }
  55% {
      transform: translate(5px, 5px)
  }
  80% {
      transform: translate(5px, -5px)
  }
  }
  
  `;

  export const TextSubHeadNote = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  // font-family: "Cabin";
  letter-spacing: .02em;
  font-weight: 400;
  position: relative;
  color: white;
  // text-shadow: 1px 2px 3px #DDEEFF,
  // 0 0 0 black,
  // -1px 1px 0 #DDEEFF;
  // 1px 1px 0 white;
  // color: rgba(254, 103, 2, .8);
  animation: fadeUpC 2s ease 0s 1 normal;
  @keyframes fadeUpC {
    from {
      opacity: 0;
      transform: translate3d(-5%,10%,0);
    }
    to {
      opacity: 1;
      transform: translate3d(-5%,0,0);
    }
       }

  @media only screen and (max-width: 40em) { 
    font-size: .6rem;
  }  /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

  @media only screen and (min-width: 40.063em) and (max-width: 64em) {
    font-size: .9rem;
   }

  @media only screen and (min-width: 64.063em) and (max-width: 90em) { 
    font-size: 1.3rem;
  } 

  @media only screen and (min-width: 90.063em) and (max-width: 120em)  { 
    font-size: 1.4rem;
    } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

  @media only screen and (min-width: 120.063em) {
    font-size: 1.5rem;
  }
  `;

  export const TextFeedback = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  font-family: "JetRegular";
  letter-spacing: .04em;
  position: relative;
  // border: 1px solid black;
  padding: 10px;
  color: white;
  top: 0;
  min-height: 0;
  max-height: 100%;
  margin: 0px 0px 5px 0px;
  text-transform: uppercase;

  @media only screen and (max-width: 40em) { 
    font-size: .6rem;
   } /* max-width 640px, mobile-only styles, use when QAing mobile issues */

   @media only screen and (min-width: 40.063em) and (max-width: 64em) {
      font-size: .8rem;
      
    } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    font-size: 1rem;
    // color: red;
    } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em)  { 
    font-size: 1.2rem;
    } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

    @media only screen and (min-width: 120.063em) {
      font-size: 1.4rem;
     } 
  

//   background: linear-gradient(
//     70deg,
//     #0ebeff,
//     #ffdd40,
//     #ae63e4,
//     #47cf73,
//     #0ebeff,
//     #ffdd40,
//     #ae63e4,
//     #47cf73
//   );
//   color: #fff;
//   font-size: 20px;
//   font-weight: bold;
//   letter-spacing: 2px;
//   word-spacing: 4px;
//   // box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

//   animation: rainbow-border 25s linear infinite;
//   -webkit-text-fill-color: transparent;
//   -webkit-background-clip: text;
//   background-size: 200% 200%;

// @keyframes rainbow-border {
// 0% {
//   background-position: 0% 50%;
// }

// 50% {
//   background-position: 100% 50%;
// }

// 100% {
//   background-position: 200% 50%;
// }
//   color: #f35626;
// 	background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
// 	-webkit-background-clip: text;
// 	-webkit-text-fill-color: transparent;
// 	-webkit-animation: hue 30s infinite linear;
// }

// @-webkit-keyframes hue {
//   from {
//     -webkit-filter: hue-rotate(0deg);
//   }
//   to {
//     -webkit-filter: hue-rotate(-360deg);
//   }
  
  }
  
  `;

  export const TextPrice = styled.p`
  font-size: 2.2rem;
  line-height: 1.6;
  font-family: "Open Sans";
  letter-spacing: .08em;
  position: relative;
  color: white;
  margin: 0px 0px 5px 0px;
  background: linear-gradient(
    70deg,
    #45275B,
    #054C66,
    #47cf73
  );
  color: #fff;
  text-transform: uppercase;
  @media only screen and (max-width: 40em) { 
    font-size: 2.4rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   
  // box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  animation: rainbow-border 25s radial infinite;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-size: 200% 200%;

@keyframes rainbow-border {
0% {
  background-position: 0% 50%;
}

50% {
  background-position: 100% 50%;
}

100% {
  background-position: 200% 50%;
}

    // background: -webkit-linear-gradient(0deg, #ff6364, #7a9dff);
    // color: #ff6364;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  // color: #fff;
  }
  
  `;

  export const ContainerButton = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: column;
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  // background-color: red;
  width: 100%;
  height: 100%;
  position: relative;
  animation: none;
  // margin-bottom: 10%;
  // border: 2px solid white;
  // border-top: 2px solid white;
  // border-bottom: 2px solid white;
  // border-right: 2px solid white;
  // transform: translateY(-60%);
  // margin-top: .5rem;
  // margin-bottom: .5rem;
  opacity: 100%;
  // border: 1px solid black;
  // border-radius: 200px;
  // animation:  glow 10s infinite;
  // box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  
  @media only screen and (max-width: 40em) { 

         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   
`;



export const Header = styled.div`
// background: #eee;
border: 3px solid #black;
border-radius: 50px;
padding: 1rem;
text-align: center;
width: 30%;
position: fixed;
align-items: center;
margin-left: 100%;
// margin-bottom: 65%;
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
//   @media only screen and (max-width: 40em) { 
// position: absolute;
// display: flex;
// justify-content: space-between;
// flex-direction: row;
// width: 100%;
// height: 25%;
// border: none;
// box-shadow: none;
// } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

`;

export const ContainerHeader = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: none;
  width: 100%;
  opacity: 100%;
  margin-top: -1%;
  margin-bottom: -1%;
  max-width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  // border: 1px solid black;
  // transform: translateX(0%);
  animation: fadeUp 1s ease 0s 1 normal;
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translate3d(0,-10%,0);
    }
    to {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }
  // box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  // border-radius: 200px;
  // animation:  glow 10s infinite;
  
  @media only screen and (max-width: 40em) { 
    width: 100%;
    transform: translateX(0%);
    border-radius: 100px;
    margin-bottom: -2%;

         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   

  @media only screen and (min-width: 64.063em) {
    // margin-top: 17%;

         } /* min-width 1025px, large screens */
`;

export const ContainerMarquee = styled.div`
  // grid-column: span 3 / auto;
  // grid-row: span 2 / auto;
  grid-row-start: 3;
  grid-column-start: 4;

  grid-row-end: 4;
  grid-column-end: 6;
  // margin: 5% 10px 0px 3%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  height: 100%
  // transform: translateY(35%);
  // border: 2px solid white;
  border-right: 2px solid white;
  border-top: 2px solid white;
  overflow: hidden;
  @media only screen and (max-width: 40em) { 
    // grid-column: 1;
    // grid-row: 3;
    // grid: 3 / 1 / 4 / 2;
    // border-left: 2px solid white;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */  
  // :hover {
  // animation: wobble 1.5s 1;
  // @keyframes wobble{
  //       25% {
  //         transform: rotate(.5deg);
  //       }
  //       50% {
  //         transform: rotate(-.5deg);
  //       }
  //       75% {
  //         transform: rotate(.5deg);
  //       }
  //       100% {
  //         transform: rotate(-.5deg);
  //       }
  //     }
  //   }

  
`;

export const ContainerHeaderBox = styled.div`
  // margin: 0% 0% 0% 0%;
  // margin-top: -2%;
  // margin-left: -3%;
  // flex: ${({ flex }) => (flex ? flex : 0)};
  // flex-direction: ${({ fd }) => (fd ? fd : "column")};
  // justify-content: ${({ jc }) => (jc ? jc : "center")};
  // align-items: ${({ ai }) => (ai ? ai : "center")};
  // background-color: white;
  width: 100%;
  height: 100%;
  // transform: translateY(35%);
  // border: 2px solid white;
  // border-left: 2px solid white;
  // border-top: 2px solid white;
  overflow: hidden;

  @media only screen and (max-width: 40em) { 
    // grid-column: 1;
    // grid-row: 2;
    // grid-area: 2 / 1 / 3 / 2;
    // height: 50%;
    border-left: none;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
      margin-top: -1%;
      margin-left: 0%;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
      

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    margin-top: -1.5%;
    margin-left: 1%;
    background: red;
  } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em) { 
    margin-top: -1%;
    margin-left: 1%;
    // background: green;
  } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) { 
    margin-top: -1%;
    margin-left: 1%;
    // background: #ffffff90;

  } /* min-width 1921px, xlarge screens */
  
  // :hover {
  // animation: wobble 1.5s 1;
  // @keyframes wobble{
  //       25% {
  //         transform: rotate(.5deg);
  //       }
  //       50% {
  //         transform: rotate(-.5deg);
  //       }
  //       75% {
  //         transform: rotate(.5deg);
  //       }
  //       100% {
  //         transform: rotate(-.5deg);
  //       }
  //     }
  //   }


  
`;

export const TextSummaryBox = styled.div`
 // margin: 5% 10px 0px 3%;
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  // background-color: white;
  min-width: 0;
  width: 100%;
  height: 108%;
  margin-top: -11%;
  min-height: auto;
  // transform: translateY(35%);
  // border: 2px solid white;
  // border-left: 2px solid white;
  // border-top: 2px solid white;
  // border-right: 2px solid white;
  overflow: hidden;

  @media only screen and (max-width: 40em) { 
    margin-top: -7.5%;
    border-right: none;
    // background: orange;
    // border-top: 2px solid white;
  }

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
      // background-color: pink;
      margin-top: -6%;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    margin-top: -7%;
    // background-color: green;
  } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
     margin-top: -7%;
    // width: 90%;
    // background-color: red;
  } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) { 
    // background-color: yellow;
    margin-top: -5%;
  } /* min-width 1921px, xlarge screens */


  // :hover {
  // animation: wobble 1.5s 1;
  // @keyframes wobble{
  //       25% {
  //         transform: rotate(.5deg);
  //       }
  //       50% {
  //         transform: rotate(-.5deg);
  //       }
  //       75% {
  //         transform: rotate(.5deg);
  //       }
  //       100% {
  //         transform: rotate(-.5deg);
  //       }
  //     }
  //   }
`;

export const ContainerCountdown = styled.div`
  // grid-column: span 4 / auto;
// margin: -180px 10px 0px -10%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  // transform: translate(25%, -101%);
  // border: 2px solid white;
  
  overflow: hidden;
  // :hover {
  // animation: wobble 1.5s 1;
  // @keyframes wobble{
  //       25% {
  //         transform: rotate(.5deg);
  //       }
  //       50% {
  //         transform: rotate(-.5deg);
  //       }
  //       75% {
  //         transform: rotate(.5deg);
  //       }
  //       100% {
  //         transform: rotate(-.5deg);
  //       }
  //     }
  //   }

  
`;

export const ContainerFeedback = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  // transform: translateY(-50%);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;

`;




  // grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
  // grid-auto-rows: 100%(1fr) 1fr 1fr 1fr 1fr;
  // grid-template-rows: repeat(auto-fit, minmax(auto, 1fr);
  // grid-template-rows: repeat(1, 1fr);

export const AngryGrid = styled.div` 
  display: grid; 
  // grid-template-rows: auto-fill(1fr) 1fr 1fr 1fr 1fr;
  // grid-template-rows: auto 1fr;
  // grid-auto-flow: column;
  // grid-auto-rows: minmax(180px, auto);
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr) );
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr))
  gap: 0px;
  height: 100%; 
  width: 100%;
  // background-color: rgba(29, 28, 26, 0.85);
  // background-color: #ffffff;
  backdrop-filter: blur(5px);
  position: relative;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #CCCCC4 ;

  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.051),
  0px 0px 7.2px rgba(255, 255, 255, 0.073), 0px 0px 13.6px rgba(255, 255, 255, 0.09),
  0px 0px 24.3px rgba(255, 255, 255, 0.107), 0px 0px 45.5px rgba(255, 255, 255, 0.129),
  0px 0px 109px rgba(255, 255, 255, 0.18);
  animation: fadeUp 3s ease 0s 1 normal;
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translate3d(0,10%,0);
    }
    to {
      opacity: 1;
      transform: translate3d(0%,0,0);
    }
  }

  @media only screen and (max-width: 40em) { 
    margin-top: -7%;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    margin-top: -5%;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 64.063em) and (max-width: 90em) { 
    // margin-top: -10%;
  }

  @media only screen and (min-width: 90.063em) and (max-width: 120em) 
    width: 90%;
    margin-top: -1%;    
   } /* min-width 1441px, xlarge screens */

   @media only screen and (min-width: 120.063em) {
    margin-top: 1%;
    }
`;
 
export const item0 = styled.div`
  // background-color: #B5EFAD30; 
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 6;
  height: 100%;
  // margin-top: -5%;
  margin-left: -1%;
  // border-bottom: 2px solid white;

  @media only screen and (max-width: 40em) { 
  // background-color: white;
  height: 100%;

`;

export const item1 = styled.div`

  // background-color: #7B976430;
  // background: rgb(232,228,229);
  // background: linear-gradient(180deg, rgba(232,228,229,1) 0%, rgba(240,238,239,1) 35%, rgba(233,229,230,1) 100%);
  grid-row-start: 2;
  grid-column-start: 1;

  grid-row-end: 3;
  grid-column-end: 6;
  height: 80%;
  
  // background-color: white;
  border-bottom: 2px solid #CCCCC4 ;
  // border-top: 2px solid #CCCCC4 ;

`;

export const item2 = styled.div`

  // background-color: #AADDDD20; 
  grid-row-start: 3;
  grid-column-start: 1;

  grid-row-end: 5;
  grid-column-end: 4;
  height: 100%;
  min-width: 0;
  // border-right: 2px solid white;
  // border-top: 2px solid white;

  

  @media only screen and (max-width: 40em) { 
    //  border-right: 2px-solid-white;

   } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

   @media only screen and (min-width: 64.063em) {
    // border-right: 2px solid #CCCCC4 ;

   } /* min-width 1025px, large screens */

`;

export const item3 = styled.div`

  // background-color: #898D9B20; 
  grid-row-start: 3;
  grid-column-start: 4;
  height: 100%;
  grid-row-end: 4;
  grid-column-end: 6;
  // border-top: 2px solid white;
  // border-bottom: 2px solid white;
  // border-left: 2px solid white;
   @media only screen and (max-width: 40em) { 
    // border-left:  2px solid white;


  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media all and (min-width: 480px) and (max-width: 768px) { 
    font-size: .7rem;
    // border-left: none;
    // border-top: 2px solid white;
  }
 /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

 @media only screen and (min-width: 64.063em) {
  // border-top: 2px solid white;

 } /* min-width 1025px, large screens */


`;

export const item4 = styled.div`

  // background-color: #E6E7DE30;  
  grid-row-start: 4;
  grid-column-start: 4;

  grid-row-end: 5;
  grid-column-end: 6;
  height: 100%;
  // border-top: 2px solid white;
  // border-left: 2px solid white;
  // border-bottom: 2px solid white;
  
  @media only screen and (max-width: 40em) { 
    // border-left:  2px solid white;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media all and (min-width: 480px) and (max-width: 768px) { 
    font-size: .7rem;
    border-left: none;
  }
  /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 64.063em) {
  border-left: none;

  } /* min-width 1025px, large screens */



`;

export const CardGallery = styled.div`
// background-color: rgba(29, 28, 26, 0.85);
backdrop-filter: blur(5px);
  width: 100%;
  // height: var(--card-height);
  padding: 0%;
  left: 0;
  // border: 2px solid #CCCCC4;
  margin-top: -3%;

  position: relative;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // opacity: 80%;
  font-size: 1.5em;
  // background: red;
  
  // color: rgb(88 199 250 / 0%);
  // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.051),
  // 0px 0px 7.2px rgba(255, 255, 255, 0.073), 0px 0px 13.6px rgba(255, 255, 255, 0.09),
  // 0px 0px 24.3px rgba(255, 255, 255, 0.107), 0px 0px 20.5px rgba(255, 255, 255, 0.129),
  // 0px 0px 109px rgba(255, 255, 255, 0.18);
  // animation: fadeUpB 0s ease 3s 1 normal;
//   @keyframes fadeUpB {
//     from {
//       opacity: 0;
//       transform: translate3d(0,10%,0);
//     }
//     to {
//       opacity: 1;
//       transform: translate3d(0%,0,0);
//     }
//   }
// }
@media only screen and (max-width: 40em) { 
  width: 123%;
  // margin-left: 5%;
  animation: none;
}
`;