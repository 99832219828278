import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import ReactDOM from 'react-dom';
import { 
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	NavLink,
} from 'react-router-dom';
// import { Link } from 'react-router-dom';
import i1 from "../assets/images/soulection-in-motion.png";
import i2 from "../assets/images/skycoin.png";
import i3 from "../assets/images/lightpurplecoin.png";
import i4 from "../assets/images/purpcoin.png";
import i5 from "../assets/images/topazcoin.png";
import i6 from "../assets/images/greencoin.png";
import i7 from "../assets/images/gem.gif";
import i13 from "../assets/images/soulection_plus.png";

// import i8 from "../assets/images/topaz.gif";
// import i9 from "../assets/images/sky.gif";
// import i10 from "../assets/images/purp.gif";
// import i11 from "../assets/images/lightpurp.gif";
// import i12 from "../assets/images/lime.gif";



// import i3 from "../assets/images/discord.svg";
// import i4 from "../assets/images/instagram.svg";
// import i5 from "../assets/images/twitter.svg";
// import Slider from "react-slick";
import { IoChevronForwardSharp, IoChevronBackSharp } from "react-icons/io5";
// import { FaDiscord, FaTwitter } from "react-icons/fa";
// import { GrInstagram } from "react-icons/gr";
// import { gsap, Power2 } from "gsap/all";
// import Canvas from "../pages/canvas";
import {AnimatedSocialIcon} from 'react-animated-social-icons';
// import "../pages/Button.js";
import Slider from "./Example";
// import backgroundH from "https://media4.giphy.com/media/QexsmmtdYNOkPhxHlj/giphy.gif?cid=ecf05e47m7jtcp4t8h9t45lv7y0ejqysrvx4jrcejwf4e1ag&rid=giphy.gif&ct=g";
// import image from "../assets/images/giphy.gif";
import Countdown from './Countdown';

import CardPage from '../pages/Mint';
import CG001 from '../pages/MintPage';








// import Image from "next/image";

// const images = [astronaut, celebrating, education, taken];


export const StyledButton = styled.button`
  border-radius: 50px;
  border: 3px solid white;
  // border: black;
  // border-style: solid;
  // background: radial-gradient(ellipse at center, rgb(247,175,157), rgb(241,126,47));
  background: none;
  margin-top: -20%;
  padding: 10px;
  color: white;
  width: 15rem;
  height: 4rem;
  cursor: pointer;
  // font-size: 1.7rem;
  text-transform: uppercase;
  opacity: 1;
  box-shadow: 0px 0px 20px #FAF8F695;

  // transition:all .5s ease-in-out;
  // animation: shadow-pulse 10s infinite;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0..5) 0px 0px 0px 1px;

  @media all and (max-width: 480px) { 
    height: 3rem;
  }

  @media only screen and (max-width: 40em) { 
    width: 5rem;
    height: 2rem;
    border: 1px solid white;
    margin-top: -2%;
    // background-color: pink;
    } /* max-width 640px, mobile-only styles, use when QAing mobile issues */

  
  @media only screen and (min-width: 40em) and (max-width: 64em)  { 
    width: 10rem;
    height: 3rem;
    border: 1px solid white;
    margin-top: -4%;
    // background-color: red;
    } /* max-width 640px, mobile-only styles, use when QAing mobile issues */


    @media only screen and (min-width: 64.063em) and (max-width: 90em)  {
      width: 13rem;
      height: 4rem;
      margin-top: -10%;
      // background-color: green;
    }

    @media only screen and (min-width: 90.063em) and (max-width: 120em) {
        width: 13rem;
        height: 4rem;
        margin-top: -3%;
        // background-color: orange
    } /* min-width 1441px, xlarge screens */

    @media only screen and (min-width: 120.063em) {
      width: 15rem;
      height: 5rem;
      margin-top: 0%;
      // background-color: gray;
     } /* min-width 1921px, xlarge screens */

  :hover {
  background: white;
  color:#00373a;
  -webkit-box-shadow: 0px 0px 10px white;
  box-shadow: 0px 0px 10px white;
  // animation: bgchange 5s linear infinite;

  @keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px #fffff20;
  }
  100% {
    box-shadow: 0 0 0 35px white;
  }
}

  
  //   @keyframes bgchange{
  //   0%{
  //     background-color: rgba(255, 255, 255, 1);
  //   }
  
  //   50%{
  //     background-color: rgba(255, 255, 255, 0.3);
  //   }
  
  //   100%{
  //     background-color: rgba(255, 255, 255, 1);
  //   }
  // }
        
  // transition: background 200ms ease-in-out;
  // animation: glow 1s infinite, fadeIn 4s ease 0s 1 normal;
  // @keyframes glow{
  //   0%{
  //   box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168), 5px 5px 20px rgb(242, 134, 52)}
  //   50%{
  //   box-shadow: -5px -5px 20px rgb(242, 134, 52), 5px 5px 20px rgb(81, 224, 210),-5px -5px 20px rgb(81, 224, 210)
  //   }
  //   100%{
  //   box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168), 5px 5px 20px rgb(242, 134, 52)
  //   }
  // }
//   @keyframes fadeIn {
//     0% {opacity: 0}
//     100% {opacity: 1}
// }

// @-webkit-keyframes fadeIn {
//     0% {opacity: 0}
//     100% {opacity: 1}
// }

  // :focus {
  //   outline: 0;
  // }
  // :before, :after {
  //   position: absolute;
  //   content: '';
  //   display: block;
  //   width: 140%;
  //   height: 100%;
  //   left: -20%;
  //   z-index: 99;
  //   transition: all ease-in-out 0.5s;
  //   background-repeat: no-repeat;
  // }
  :before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, transparent 20%, #1155cc 20%, transparent 30%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, transparent 10%, #02063d 15%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  }
  :after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, transparent 10%, #1155cc 15%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  }
  :active {
    transform: scale(0.9);
    background-color: #FEFBFB;
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
    color: black;
  }
  // @keyframes wobble{
  //     25% {
  //       transform: rotate(15deg);
  //     }
  //     50% {
  //       transform: rotate(-30deg);
  //     }
  //     75% {
  //       transform: rotate(5deg);
  //     }
  //     100% {
  //       transform: rotate(0deg);
  //     }
  //   }
  // box-shadow: 00 15px 50px $blue,
  // 0 -15px 60px lighten($blue,30%),
  // inset 0 2px 2px lighten($blue,20%);
  // -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // -webkit-transition:all 0.3s ease;
  // -webkit-touch-callout: none; 
  //   -webkit-user-select: none; 
  //      -moz-user-select: none; 
  //       -ms-user-select: none; 
  //           user-select: none;
  // :active {
  //   box-shadow: none;
  //   -webkit-box-shadow: none;
  //   -moz-box-shadow: none;
  //   -webkit-transform: scale(0.8);
  //   -webkit-transition:all 0.1s ease;
  //   outline: none;
  //   background: radial-gradient(ellipse at center, lighten($blue,15%), lighten($blue,10%));
  //   box-shadow: 0 15px 80px $blue,
  //               0 -15px 80px lighten($blue,30%),
  //               inset 0 -2px 2px lighten($blue,10%);
  // }
  // &:focus { outline: none; }
  // :hover {
  //   // animation-play-state: paused;
  //   // background-color: #fbd8e3;
  //   border-color: white;
  //   // color: gold;
  //   // background: var(--hover);
  // }
  // }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  @media (min-width: 767px) {
    width: 80%;
    height: 80%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;



export const MainImg = styled.img`
  width: 42em;
  height: 42em;
  transform: translate(0%, -5%);
  @media (max-width: 767px) {
    width: 70px;
    height: 70px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const FooterImg = styled.img`
  width: 25%;
  height: 25%;
  display: flex;
  flex-direction: row;
  @media (min-width: 767px) {
    width: 150px;
    height: 35px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const FContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretched;
  width: 35%;
  margin: 50px;
  @media (max-width: 767px) {
    flex-direction: row;
    width: 60%;
    justify-content: space-between;
  }
`;

export const ButtonImg = styled.img`
  width: 55%;
  height: 55%;
  display: flex;
  flex-direction: row;
  @media (min-width: 767px) {
    width: 120px;
    height: 120px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const TopHeader = styled.div`
display: flex;
// background: #eee;
padding: 1rem;
margin-bottom: 0%;
text-align: center;
width: 100%;
height: 25px;
margin-top: 13%;
align-items: center;
justify-content: center;
position: relative;
// animation: fadeIn 1s ease 0s 1 normal;
// @keyframes fadeIn {
//   0% {opacity: 0}
//   100% {opacity: 1}
// }

@-webkit-keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}
// @media only screen and (max-width: 40em) { 
//   // max-width: 70%;
//   // margin-right: 100%;
//   // background-color: black;
// } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
`;


export const HighlightSpan = styled.span`
  padding: 0em 0em;
  background: none;
  color: white;
  font-family: Arial;
  font-size: 1.5rem;
  // line-height: .5rem;
  // box-shadow: .2em 0 0 #B976E2, -.2em 0 0 #B976E2;
  @media only screen and (max-width: 40em) { 
    font-size: 1rem;
  }

  @media only screen and (min-width: 90.063em) {
    font-size: 2.5rem;
    
   } /* min-width 1441px, xlarge screens */
`;

export const ArtistSpan = styled.span`
   font-weight: 700;
  text-decoration: none;
  color: #E4F4FF;
  font-family: "JetSemiBold";
  // box-shadow: .2em 0 0 #F6722B, -.2em 0 0 #F6722B;

  @media only screen and (max-width: 40em) { 
    font-size: .7rem;
    }

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 

  }

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    font-size: 2rem;
     } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
      font-size: 2.2rem;
      
     } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) { 
      font-size: 3rem;
      } /* min-width 1921px, xlarge screens */
`;

export const RohoSpan = styled.span`
  //  font-weight: 700;
  // font-size: 2rem;
  text-decoration: none;
  color: #FFF0E4;
  font-family: "JetLight";
  // box-shadow: .2em 0 0 #F6722B, -.2em 0 0 #F6722B;

  // @media only screen and (max-width: 40em) { 
  //   font-size: .7rem;
  //   }

  // @media only screen and (min-width: 40.063em) and (max-width: 64em) { 

  // }

  // @media only screen and (min-width: 64.063em) and (max-width: 90em) {
  //   font-size: 2rem;
  //    } /* min-width 1025px, large screens */

  // @media only screen and (min-width: 90.063em) and (max-width: 120em) {
  //     font-size: 2.2rem;
      
  //    } /* min-width 1441px, xlarge screens */

  // @media only screen and (min-width: 120.063em) { 
  //     font-size: 3rem;
  //     } /* min-width 1921px, xlarge screens */
`;

export const BoldSpan = styled.span`
font-weight: 400;
font-family: "JetSemiBold";
`;

export const CommunitySpan = styled.span`
// font-weight: 400;
font-family: JetExBold;
font-size: 1rem;
color: #FFFEEF;
// text-decoration: underline;

@media only screen and (max-width: 40em) { 
  font-size: .5rem;
}

@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
  font-size: .9rem;
} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  font-size: 1.5rem;
   } /* min-width 1025px, large screens */

@media only screen and (min-width: 90.063em) and (max-width: 120em) {
  font-size: 1.6rem;
 } /* min-width 1441px, xlarge screens */

@media only screen and (min-width: 120.063em) { 
  font-size: 1.7rem;
 } /* min-width 1921px, xlarge screens */

`;

export const SummarySpan = styled.span`
  // padding: .05em 0;
  background: none;
  color: #ffffff;
  letter-spacing: 0em;
  line-height: 1;
  font-size: 1rem;
  padding-top: -1%;
  transform: none;
  // box-shadow: .2em 0 0 #F6722B, -.2em 0 0 #F6722B;

  // @media all and (min-width: 480px) and (max-width: 768px) { 
  //   font-size: .6rem;
  // } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

   @media only screen and (max-width: 40em) { 
    font-size: .45rem;
    line-height: -5;
    }

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 

    }

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    font-size: .9rem;
     } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
      font-size: 1.1rem;
      
     } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) { 
      font-size: 1.5rem;
      } /* min-width 1921px, xlarge screens */
`;

export const TextSpanNumber = styled.span`
  font-size: 3rem;
  font-family: "futura";
  letter-spacing: 0em;
  font-weight: 100;

  @media only screen and (max-width: 40em) { 
    letter-spacing: 0em;
    font-size: 2.2rem;
  }

  @media only screen and (min-width: 40.063em) and (max-width: 64em) {
    font-size: 3.5rem;
   }

   @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    font-size: 5rem;
   }

  @media only screen and (min-width: 90.063em) and (max-width: 120em)  { 
    font-size: 6rem;
    } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

    @media only screen and (min-width: 120.063em) { 
      font-size: 7rem;
    }

`;

export const TextSpanColor = styled.span`
    color: #E5ECF8;
  @media only screen and (min-width: 64.063em) {
  
   } /* min-width 1025px, large screens */

   @media only screen and (min-width: 90.063em) {
    
   } /* min-width 1441px, xlarge screens */

   
  
`;

export const TextSpanFirst = styled.span`
  font-size: 2rem;
  line-height: 1;
  color: #E4F4FF;
  font-weight: 700;
  
  font-family: "JetSemiBold";
  @media only screen and (max-width: 40em) { 
    font-size: 1.4rem;
    }

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    font-size: 1.7rem;
  }

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    font-size: 2.4rem;

     } /* min-width 1025px, large screens */

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
      font-size: 4rem;
     } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) { 
      font-size: 4.5rem;
      } /* min-width 1921px, xlarge screens */
`;

export const SoulectionSpan = styled.span`
  // font-size: 2rem;
  line-height: 1;
  color: #FFFEEF;
  // font-weight: 700;

  font-family: "JetLight";
  // @media only screen and (max-width: 40em) { 
  //   font-size: 1.4rem;
  //   }

  // @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
  //   font-size: 1.7rem;
  // }

  // @media only screen and (min-width: 64.063em) and (max-width: 90em) {
  //   font-size: 2.4rem;

  //    } /* min-width 1025px, large screens */

  // @media only screen and (min-width: 90.063em) and (max-width: 120em) {
  //     font-size: 4rem;
  //    } /* min-width 1441px, xlarge screens */

  // @media only screen and (min-width: 120.063em) { 
  //     font-size: 4.5rem;
  //     } /* min-width 1921px, xlarge screens */
`;


export const SmallSpan = styled.p`
  font-size: 1rem;
  margin-top: 1%;
  margin-bottom: 1%;

  @media only screen and (max-width: 40em) { 
    font-size: .3rem;
    } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   

    @media only screen and (min-width: 40.063em) and (max-width: 64em)  {
    font-size: .7rem;
    // background-color: white;
    }

    @media only screen and (min-width: 90.063em) and (max-width: 120em) {
    font-size: 1.5rem;
    } /* min-width 1441px, xlarge screens */

    @media only screen and (min-width: 120.063em) { 
    font-size: 1.2rem;  
    } /* min-width 1921px, xlarge screens */
`;

// export const TextSpanSoulection = styled.span`
//   font-size: 1.5rem;
// `;

export const SpanThin = styled.span`
  font-family: JetExBold;
  font-size: 1rem;
  font-weight: 800;
  color: #FFFEEF;

  @media only screen and (max-width: 40em) { 
    font-size: .4rem;
  }

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    font-size: .8rem;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
    font-size: 1.5rem;
   } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) { 
    font-size: 1.3rem;
   } /* min-width 1921px, xlarge screens */

   

   
`;

export const TextSpan = styled.span`
  font-size: 1.5rem;
  @media only screen and (max-width: 40em) { 
  font-size: 1.1rem;
  line-height: 1em;
  }

  @media only screen and (min-width: 90.063em) {
    font-size: 2.5rem;
    
   } /* min-width 1441px, xlarge screens */
`;

export const TextSpan2 = styled.span`
  font-size: 1.3rem;
  
  // @media only screen and (max-width: 40em) { 
  // font-size: .8rem;
  // // line-height: 1em;
  // }

  @media only screen and (min-width: 90.063em) {
    font-size: 2.5rem;
    
   } /* min-width 1441px, xlarge screens */
`;



export const SubSpan = styled.span`
  font-size: 2rem; 
  // line-height: 1rem; 

  @media only screen and (max-width: 40em) { 
    font-size: .8rem;
    // vertical-align: baseline;
  }

  @media only screen and (min-width: 40.063em) and (max-width: 64em) {
    font-size: 1.2rem;
  }

  @media only screen and (min-width: 64.063em) and (max-width: 90em) { 
    font-size: 1.5rem;
    // background-color: green;

  }

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
    font-size: 2.2rem;
    // background-color: white;
   } /* min-width 1441px, xlarge screens */

   @media only screen and (min-width: 120.063em) {
    font-size: 2.2rem;
   } /* min-width 1921px, xlarge screens */
`;

// export const Card = styled.div`
//   display: grid;
//   // grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
//   // grid-auto-rows: fit-content(1fr) 1fr 1fr 1fr 1fr;
//   // grid-template-rows: repeat(auto-fit, minmax(auto, 1fr);
//   // grid-template-rows: repeat(1, 1fr);
//   grid-template-rows: 1fr 1fr 1fr;
//   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
//   gap: 0px;
//   // flex-direction: column;
//   background-color: rgba(29, 28, 26, 0.85);
//   backdrop-filter: blur(5px);
//   width: 100%;
//   height: 100%;
//   // height: var(--card-height);
//   padding: 0%;
//   // left: 10%;
//   border: 2px solid white;

//   position: relative;
//   border-radius: 10px;
//   justify-content: center;
//   align-items: center;
//   text-align: center;

//   // opacity: 80%;
//   font-size: 1.5em;
//   // color: rgb(88 199 250 / 0%);
//   box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.051),
//   0px 0px 7.2px rgba(255, 255, 255, 0.073), 0px 0px 13.6px rgba(255, 255, 255, 0.09),
//   0px 0px 24.3px rgba(255, 255, 255, 0.107), 0px 0px 45.5px rgba(255, 255, 255, 0.129),
//   0px 0px 109px rgba(255, 255, 255, 0.18);
//   // animation: fadeUp 3s ease 0s 1 normal;
//   // @keyframes fadeUp {
//   //   from {
//   //     opacity: 0;
//   //     transform: translate3d(0,10%,0);
//   //   }
//   //   to {
//   //     opacity: 1;
//   //     transform: translate3d(0%,0,0);
//   //   }
//   // }
// @media only screen and (max-width: 40em) { 
//   width: 100%;
//   transform: translateX(0%);
//   animation: none;
//   // grid-template-columns: repeat(1, 20fr);
//   // grid-template-rows: 1fr 1fr 1fr 1fr 3fr;
//   // grid-template-columns: 1fr
// }
// `;

export const CardInfo = styled.div`
background-color: rgba(29, 28, 26, 0.85);
backdrop-filter: blur(5px);
  width: 80%;
  max-width: 80%;
  // height: var(--card-height);
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 1%;
  padding-right: 1%;
  left: 0;
  border: 2px solid #CCCCC4;

  position: relative;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  // opacity: 80%;
  font-size: 1.5em;
  // color: rgb(88 199 250 / 0%);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.051),
  0px 0px 7.2px rgba(255, 255, 255, 0.073), 0px 0px 13.6px rgba(255, 255, 255, 0.09),
  0px 0px 24.3px rgba(255, 255, 255, 0.107), 0px 0px 20.5px rgba(255, 255, 255, 0.129),
  0px 0px 109px rgba(255, 255, 255, 0.18);
  // animation: fadeUpB 0s ease 3s 1 normal;
//   @keyframes fadeUpB {
//     from {
//       opacity: 0;
//       transform: translate3d(0,10%,0);
//     }
//     to {
//       opacity: 1;
//       transform: translate3d(0%,0,0);
//     }
//   }
// }
@media only screen and (max-width: 40em) { 
  width: 123%;
  // transform: translate(-.5%, 45%);
  animation: none;
}
@media only screen and (min-width: 64.063em) and (max-width: 90em) { 
  width: 80%;
} 

@media only screen and (min-width: 90.063em) and (max-width: 120em)  { 
  width: 80%;
  } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

@media only screen and (min-width: 120.063em) { 
  width: 60%;
  
    }
`;



export const Card = styled.div`
position: relative;
min-width: 320px;
height: 440px;
`;

export const CoinContainer = styled.div`
  display: flex;
  // flex-direction: row;
  // grid-row: span 3 / auto;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 6;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  // border-right: 2px solid white;
  // border-top: 2px solid white;
  border-bottom: 1px solid white;
  // border-radius: 10px;
  // margin: -16% 0px 0px 0%;
  // padding: 0 0px 0 0;
  // transform: translate(-99%, 40.7%);
  @media only screen and (max-width: 40em) { 
    // border-right: 2px solid white;
    // width: 100%;
    // grid-column: 1;
    // grid-row: 1;
    // padding-bottom: 5%;
    // padding-top: 2%;
    // grid-area: 1 / 1 / 2 / 2;
    // grid-row-start: 1;
    // grid-column-start: 1;
    // grid-row-end: 2;
    // grid-column-end: 2;
    // transform: translateX(-15%);
  }
  `;

  export const CoinImg1 = styled.img`
  width: 8rem;
  height: 8rem;
  pointer-events: none;
  transform: translateX(80%);
  // padding: 20px 20px 20px 20px;
  @media only screen and (max-width: 40em) { 
    width: 6rem;
    height: 6rem;
    padding: 0px, 0px, 0px, 0px;
    transform: translateX(140%);

  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */
  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    width: 8rem;
    height: 8rem;
    padding: 0px 0px 0px 0px;
    transform: translateX(105%);
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    width: 15rem;
    height: 15rem;
    transform: translateX(120%);
   } /* min-width 1025px, large screens */
`;

export const CoinImg2 = styled.img`
width: 11rem;
height: 11rem;
pointer-events: none;
transform: translateX(30%);

// padding: 20px 20px 20px 20px;
@media only screen and (max-width: 40em) { 
  width: 9rem;
  height: 9rem;
  padding: 0px, 0px, 0px, 0px;
  transform: translateX(40%);

} /* max-width 640px, mobile-only styles, use when QAing mobile issues */
@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
  width: 12rem;
  height: 12rem;
  padding: 0px 0px 0px 0px;
  transform: translateX(35%);

} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  width: 19rem;
  height: 19rem;
  transform: translateX(40%);

 } /* min-width 1025px, large screens */
`;

export const CoinImg3 = styled.img`
width: 15rem;
height: 15rem;
pointer-events: none;
// padding: 20px 20px 20px 20px;
@media only screen and (max-width: 40em) { 
  width: 13rem;
  height: 13rem;
  padding: 0px, 0px, 0px, 0px;
} /* max-width 640px, mobile-only styles, use when QAing mobile issues */
@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
  width: 16rem;
  height: 16rem;
  padding: 0px 0px 0px 0px;
} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  width: 24rem;
  height: 24rem;
 } /* min-width 1025px, large screens */
`;

export const CoinImg4 = styled.img`
width: 11rem;
height: 11rem;
pointer-events: none;
transform: translateX(-30%);

// padding: 20px 20px 20px 20px;
@media only screen and (max-width: 40em) { 
  width: 9rem;
  height: 9rem;
  padding: 0px, 0px, 0px, 0px;
  transform: translateX(-40%);

} /* max-width 640px, mobile-only styles, use when QAing mobile issues */
@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
  width: 12rem;
  height: 12rem;
  padding: 0px 0px 0px 0px;
  transform: translateX(-35%);

} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  width: 19rem;
  height: 19rem;
  transform: translateX(-40%);

 } /* min-width 1025px, large screens */
`;

export const CoinImg5 = styled.img`
width: 8rem;
height: 8rem;
pointer-events: none;
transform: translateX(-80%);

// padding: 20px 20px 20px 20px;
@media only screen and (max-width: 40em) { 
  width: 6rem;
  height: 6rem;
  padding: 0px, 0px, 0px, 0px;
  transform: translateX(-140%);

} /* max-width 640px, mobile-only styles, use when QAing mobile issues */
@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
  width: 8rem;
  height: 8rem;
  padding: 0px 0px 0px 0px;
  transform: translateX(-105%);

} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  width: 15rem;
  height: 15rem;
  transform: translateX(-120%);

 } /* min-width 1025px, large screens */

//  @media only screen and (min-width: 90.063em)  { 
//   width: 44rem;
//   height: 44rem;
//   transform: translateX(40%);
//   } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */
  
`;

export const CoinImg8 = styled.img`
width: 8rem;
height: 8rem;
pointer-events: none;
transform: translateX(40%);
// padding: 20px 20px 20px 20px;

@media only screen and (max-width: 40em) { 
  width: 5rem;
  height: 5rem;
  padding: 0px, 0px, 0px, 0px;
  transform: translateX(225%);

} /* max-width 640px, mobile-only styles, use when QAing mobile issues */
@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
  width: 8rem;
  height: 8rem;
  padding: 0px 0px 0px 0px;
  transform: translateX(80%);
} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) and (max-width: 90em)  {
  width: 15rem;
  height: 15rem;
  transform: translateX(110%);
 } /* min-width 1025px, large screens */

 @media only screen and (min-width: 90.063em) and (max-width: 120em) { 
  width: 22rem;
  height: 22rem;
  transform: translateX(105%);
  } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

  @media only screen and (min-width: 120.063em) {
    width: 18rem;
    height: 18rem;
    transform: translateX(55%);
  }
`;

export const CoinImg9 = styled.img`
width: 11rem;
height: 11rem;
pointer-events: none;
transform: translateX(40%);

// padding: 20px 20px 20px 20px;

@media only screen and (max-width: 40em) { 
width: 8rem;
height: 8rem;
padding: 0px, 0px, 0px, 0px;
transform: translateX(104%);

} /* max-width 640px, mobile-only styles, use when QAing mobile issues */
@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
width: 12rem;
height: 12rem;
padding: 0px 0px 0px 0px;
transform: translateX(35%);

} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) and (max-width: 90em)  {
width: 21rem;
height: 21rem;
transform: translateX(45%);

} /* min-width 1025px, large screens */

@media only screen and (min-width: 90.063em) and (max-width: 120em) { 
  width: 30rem;
  height: 30rem;
  transform: translateX(45%);
  } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

  @media only screen and (min-width: 120.063em) {
    width: 25rem;
    height: 25rem;
    transform: translateX(20%);
  
`;

export const CoinImg10 = styled.img`
width: 15rem;
height: 15rem;
pointer-events: none;
// padding: 20px 20px 20px 20px;

@media only screen and (max-width: 40em) { 
width: 12rem;
height: 12rem;
transform: translateX(30%);
padding: 0px, 0px, 0px, 0px;
} /* max-width 640px, mobile-only styles, use when QAing mobile issues */

@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
width: 16rem;
height: 16rem;
padding: 0px 0px 0px 0px;
} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) and (max-width: 90em)  {
width: 28rem;
height: 28rem;
} /* min-width 1025px, large screens */

@media only screen and (min-width: 90.063em) and (max-width: 120em) { 
  width: 36rem;
  height: 36rem;
  } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

  @media only screen and (min-width: 120.063em) {
    width: 31rem;
    height: 31rem;
  
`;

export const CoinImg11 = styled.img`
width: 11rem;
height: 11rem;
pointer-events: none;
transform: translateX(-20%);

// padding: 20px 20px 20px 20px;

@media only screen and (max-width: 40em) { 
width: 8rem;
height: 8rem;
padding: 0px, 0px, 0px, 0px;
transform: translateX(-16%);

} /* max-width 640px, mobile-only styles, use when QAing mobile issues */
@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
width: 12rem;
height: 12rem;
padding: 0px 0px 0px 0px;
transform: translateX(-35%);

} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) and (max-width: 90em)  {
width: 21rem;
height: 21rem;
transform: translateX(-45%);

} /* min-width 1025px, large screens */

@media only screen and (min-width: 90.063em) and (max-width: 120em) { 
  width: 30rem;
  height: 30rem;
  transform: translateX(-45%);
  } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

@media only screen and (min-width: 120.063em) {
  width: 25rem;
  height: 25rem;
  transform: translateX(-20%);  
`;

export const CoinImg12 = styled.img`
width: 8rem;
height: 8rem;
pointer-events: none;
transform: translateX(-40%);
// padding: 20px 20px 20px 20px;

@media only screen and (max-width: 40em) { 
width: 5rem;
height: 5rem;
padding: 0px, 0px, 0px, 0px;
transform: translateX(-87%);
} /* max-width 640px, mobile-only styles, use when QAing mobile issues */

@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
width: 8rem;
height: 8rem;
padding: 0px 0px 0px 0px;
transform: translateX(-80%);
} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

@media only screen and (min-width: 64.063em) and (max-width: 90em)  {
width: 15rem;
height: 15rem;
transform: translateX(-110%);
} /* min-width 1025px, large screens */

@media only screen and (min-width: 90.063em) and (max-width: 120em) { 
width: 22rem;
height: 22rem;
transform: translateX(-105%);
// background-color: red;
} /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

@media only screen and (min-width: 120.063em) {
width: 18rem;
height: 18rem;
transform: translateX(-55%);

}
`;

  export const ImgContainer = styled.div`
  // display: flex;
  display: flex;
  // background-color: white;
  // flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: -2.75%;
  // margin: -21% 0px 0px 0%;
  padding: 00px 0px 0px 0px;
  // transform: translateY(-20%);
// transform: translate(-99%, 40.7%);
  
  @media only screen and (max-width: 40em) { 
    transform: translateX(-16%);
    padding: 00px 0px 00px 0px;
    height: 100%;
    // background: pink;
    }
    @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    height: 100%;
    // background: green;
    margin-top: -2%;
    }

    @media only screen and (min-width: 64.063em) and (max-width: 90em) {
      padding: 10px 0px 20px 0px;
      // background: blue;
    } /* min-width 1025px, large screens */

    @media only screen and (min-width: 90.063em) { 
      padding: 00px 0px 00px 0px;
      margin: 0% 0px 0px 0%;
      // background: yellow;
      margin-top: -2%;
    } /* min-width 1441px, xlarge screens */

    @media only screen and (min-width: 120.063em) { 
      // background: red;
    }

  `;

  export const ImgHeader = styled.img`
width: 50rem;
height: auto;
margin-bottom: -5%;
margin-top: 2%;
align-items: center;
display: flex;
// position: absolute;
justify-content: center;
flex-direction: column;
pointer-events: none;
animation: glitch1 15.5s infinite;
:nth-child(2) {
  color: #67f3da;
  animation: glitch2 15.5s infinite;
}

:nth-child(3) {
  color: #f16f6f;
  animation: glitch3 15.5s infinite;
}
/*Keyframes*/

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }
  7% {
    transform: skew(-0.5deg, -0.7deg);
    opacity: 0.75;
  }
  10% {
    transform: none;
    opacity: 1;
  }
  27% {
    transform: none;
    opacity: 1;
  }
  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }
  35% {
    transform: none;
    opacity: 1;
  }
  52% {
    transform: none;
    opacity: 1;
  }
  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }
  50% {
    transform: none;
    opacity: 1;
  }
  72% {
    transform: none;
    opacity: 1;
  }
  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }
  80% {
    transform: none;
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes glitch2 {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
}

@keyframes glitch3 {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(5px, 1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(2px, 6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
}

// transform: translateX(-40%);
// padding: 20px 20px 20px 20px;

@media only screen and (max-width: 40em) { 
  margin-bottom: -10%;
  margin-top: -4%;
  height: 70%;
  width: 25rem;
  // animation: none;
  // background: red;

}

@media only screen and (min-width: 40em) and (max-width: 64em)  { 
  // background: red;
  margin-bottom: -5%;
  margin-top: -5%;
  width: 35rem;

  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */


  @media only screen and (min-width: 64.063em) and (max-width: 90em)  {
    margin-bottom: -5%;
    margin-top: -5%;

    // background-color: green;
  }

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
    margin-top: -5%;
    margin-bottom: -5%;
    // background: red;


  } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) {
    // background: red;
    margin-top: -3%;
    width: 55rem;


   } /* min-width 1921px, xlarge screens */

// width: 5rem;
// height: 5rem;
// padding: 0px, 0px, 0px, 0px;
// transform: translateX(-87%);
} /* max-width 640px, mobile-only styles, use when QAing mobile issues */
`;



// export const Canvas = props => {
//   const canvasRef = useRef(null)
  
//   useEffect(() => {
//   // const canvas = document.querySelector('#canva')
//   let tl = gsap.timeline({ease:Power2.in});
//   // const c = canva.getContext('2d')
//   const canvas = canvasRef.current
//   const context = canvas.getContext('2d')
//   canvas.height = window.window.innerHeight
//   canvas.width = window.window.innerWidth
  
//   const btc = document.querySelector('#btc')
  
//   let _particles = []
  
//   class Particles {
//     constructor(x,y,pSize,color){
//       this.x = x
//       this.y = y
//       this.pSize = pSize
//       this.color = color
//       this.mass = Math.random() * 5 -2.5
//       this.dir = Math.random() * 2
      
//     }
//     draw(){
//       context.beginPath()
//       context.arc(this.x,this.y,this.pSize,0,Math.PI *2)
//       context.fillStyle = this.color
//       context.fill()
      
//     }
//     update(){
//       this.y -= this.mass 
//       this.x +=  this.dir
//       if(this.pSize > 0.1){
//         this.pSize -= 0.2
//       }
      
//     }
//   }
  
  
//   let int 
//   function click(){
//     let basic = btc.getBoundingClientRect()
//     let colors = ['#ff7070', '#b4ffa5', '#ba90fa', '#6b84e5'];
//     let size = Math.random() * 20 + 10
//     let x = basic.x + Math.random() * (40 - 40) + 40
//     let y = basic.y + 50
//     int = setInterval(() => {
//           let color = colors[Math.floor(Math.random() * colors.length)];
  
//        _particles.push(new Particles(x,y,size,color))
//   }, 50);
  
//     tl.to('#btc',{scale:0.8,duration:0.05})
//       .to('#btc',{scale:1,duration:0.05,onComplete:()=>{
//              clearInterval(int)
  
//       }})
  
//   }
  
//   function spawn(){
//     _particles.forEach((p,index)=>{
     
//       p.draw()
//       p.update()
    
//       if(p.pSize < 1){
//         _particles.splice(index,1)
          
     
//       }
//     })
//   }
  
  
//   function anima(){
//   context.clearRect(0,0,canvas.width,canvas.height)
    
//       btc.addEventListener('click',click)
  
  
//     spawn()
//       requestAnimationFrame(anima) 
//   }
//   anima() 
//   }, [])
  
//   return <canvas ref={canvasRef} {...props}/> }


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const awaitCalc = data.cost * 10e-19; 
  const [mintAmount, setMintAmount] = useState(1);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
    },
    MAX_SUPPLY: 1000,
  });
  // const [animate, setAnimate] = useState(false);

  // const handleClick = () => setAnimate(!animate);

  // async function awaitCalc() {
  //   for (data.cost * 10e-19); 
  // }

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Now minting your GEM. Please Confirm the transaction in your Wallet.");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(_amount)
      .send({
        gasLimit: "2850000",
        // to: "0x2d2404314C704b6F5D8C030d3259F224d500ca99",
        to: "0x0Cd73D6BC22b74ee281e9e9d67876Be85c82863F",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((awaitCalc * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Your GEM has minted successfully! You can view it on https://opensea.io"
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  window.addEventListener("contextmenu", e => e.preventDefault());



  // const [imageIndex, setImageIndex] = useState(0);
  // const small = window.matchMedia('(max-width: 600px)');

  // const settings = {
  //   infinite: true,
  //   lazyLoad: true,
  //   speed: 300,
  //   dots: false,
  //   variableWidth: true,
  //   adaptiveHeight: true,
  //   focusOnSelect: true,
  //   cssEase: 'linear',
  //   slidesToShow: 3,
  //   centerMode: true,
  //   centerPadding: 0,
  //   touchMove: true,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   beforeChange: (current, next) => setImageIndex(next),

  //             responsive: [                        
  //             {
  //               breakpoint: 576,
  //               settings: {
  //                 centerMode: false,
  //                 variableWidth: false,
  //               }
  //             },
  //         ]
  // };

  // if (small.matches) {
  //   settings.slidesToShow = 1
  // }

  return (
    <Router>
    <s.Screen style={{ 
        backgroundColor: "#171615"
        }}>
          

      <s.Container flex={1} ai={"center"} style={{ padding: 10 }}>
 
        <ResponsiveWrapper flex={1} style={{ padding: 12 }}>

        <s.ContainerHeader>  
        <Link to="/" exact className="nav-link" activeClassName="active-link">
                 <ImgHeader alt={"logo"} 
                src={"https://soulmint.s3.us-west-1.amazonaws.com/soulection_plus.png"}
                style={{ zIndex: ""}} /> </Link>
                               {/* <ImgHeader alt={"logo"} 
                src={"https://soulmint.s3.us-west-1.amazonaws.com/soulection_plus.png"}
                style={{ zIndex: ""}} /> 
                               <ImgHeader alt={"logo"} 
                src={"https://soulmint.s3.us-west-1.amazonaws.com/soulection_plus.png"}
                style={{ zIndex: ""}} />  */}
          
        {/* <s.TextHeaderMB style={{ textAlign: "center", margin: 0, fontWeight: 600, fontFamily: "conthraxsb", textTransform: "uppercase" }}>
                SOULECTION+ <br/>
            </s.TextHeaderMB>
            <s.TextHeaderMB style={{ textAlign: "center", margin: 0, fontWeight: 600, fontFamily: "conthraxsb", textTransform: "uppercase" }}>
                SOULECTION+ <br/>
            </s.TextHeaderMB>
            <s.TextHeaderMB style={{ textAlign: "center", margin: 0, fontWeight: 600, fontFamily: "conthraxsb", textTransform: "uppercase" }}>
                SOULECTION+ <br/>
            </s.TextHeaderMB> */}

            
          {/* <s.TextHeaderSub style={{ textAlign: "center", margin: 0, fontFamily: "futura" }}>
                 COMMUNITY DIGITAL COLLECTIBLE 
          </s.TextHeaderSub> */}

        {/* <TopHeader> */}
          
          
              {/* <LogoImg alt={"logo"} 
                src={i2}
                flex={1}
                fd={"row"}
                ai={"center"}
                jc={"center"} />  */}
          {/* </TopHeader> */}
        {/* <s.SpacerLarge /> */}


          {/* <s.TextHeader style={{ textAlign: "center", margin: 0, fontFamily: "Bebas" }}>
                Presents
          </s.TextHeader> */}
          {/* <s.TextHeaderSub style={{ textAlign: "center", margin: 0, fontFamily: "futura" }}>
                - A SOULECTION<SubSpan>+&nbsp;</SubSpan>
                    COLLECTIBLE - <br />
          </s.TextHeaderSub> */}
          {/* <s.SpacerSmall /> */}
          {/* <s.SpacerLarge /> */}

           </s.ContainerHeader>
          
             <s.SpacerLarge />
              <s.SpacerLarge />
              <s.SpacerSmall />
          
          {/* <CarouselContainer> */}
          {/* <MainImg 
              flex={1}
              jc={"center"}
              ai={"center"}
              alt={"main-image"} src={i1} />  */}
          
        {/* <s.SpacerMedium />
            {/* <Slider heading="Slider" slides={slideData} /> */}
          {/* </CarouselContainer > */}

      {/* <s.SpacerLarge /> */}
          
          {/* <s.SpacerMedium /> */}
         

        {/* <s.ContainerMarquee>
          
        </s.ContainerMarquee> */}
        {/* <s.SpacerSmall />
        <s.SpacerLarge /> */}
        {/* <s.AngryGrid>
          <s.item0> */}
{/* 
          <s.ContainerHeaderBox>
                <s.TextHeader style={{ textAlign: "left", margin: 0 }}>
                <HighlightSpan>NAME <br /></HighlightSpan>
                COMMUNITY GEMS 001
                </s.TextHeader>
            </s.ContainerHeaderBox> */}

          {/* </s.item0>

          <s.item1>
              <ImgContainer> */}
              {/* <CoinImg1 alt={"logo"} 
                src={i2}
                style={{ zIndex: ""}} /> 
              <CoinImg2 alt={"logo"} 
                src={i3}
                style={{ zIndex: "98"}} /> 
              <CoinImg3 alt={"logo"} 
                src={i4}
                style={{ zIndex: "99" }} /> 
              <CoinImg4 alt={"logo"} 
                src={i5}
                style={{ zIndex: "98"}} /> 
              <CoinImg5 alt={"logo"} 
                src={i6}
                style={{ zIndex: ""}} />     */}

              {/* <CoinImg8 alt={"logo"} 
                src={"https://soulmint.s3.us-west-1.amazonaws.com/topaz.gif"}
                style={{ zIndex: ""}} /> 
              <CoinImg9 alt={"logo"} 
                src={"https://soulmint.s3.us-west-1.amazonaws.com/sky.gif"}
                style={{ zIndex: "98"}} /> 
              <CoinImg10 alt={"logo"} 
                src={"https://soulmint.s3.us-west-1.amazonaws.com/purp.gif"}
                style={{ zIndex: "99" }} /> 
              <CoinImg11 alt={"logo"} 
                src={"https://soulmint.s3.us-west-1.amazonaws.com/lightpurp.gif"}
                style={{ zIndex: "98"}} /> 
              <CoinImg12 alt={"logo"} 
                src={"https://soulmint.s3.us-west-1.amazonaws.com/lime.gif"}
                style={{ zIndex: ""}} />  

                </ImgContainer>
          </s.item1>

          <s.item2>

          <s.TextSummaryBox>
          <s.TextSummary style={{ textAlign: "left", margin: 0, fontFamily: "JetThin", fontWeight: "200", textTransform: "none" }}>
            <CommunitySpan>COMMUNITY_GEMS_001:</CommunitySpan> <br /> <br />
            <Link to="/" exact className="nav-link" activeClassName="active-link">
							Trang chủ</Link>
              <Link to="/social" exact className="nav-link" activeClassName="active-link">
							Trang </Link>
            <a href="https://soulection.com" target="_blank" rel="noreferrer noopener"><SoulectionSpan>SOULECTION</SoulectionSpan></a> has a long history of utilizing the internet to the advantage of our artists and culture. 
            <br /><br /> We believe web3 & blockchain technologies offer a rare opportunity to connect directly to our core supporters, and create systems in line with our ethos that can withstand the test of time.
            <br /><br />
            We partnered with 3D designer + music maker <a href="https://linktr.ee/ROHO" target="_blank" rel="noreferrer noopener"><RohoSpan>ROHO</RohoSpan></a> to create a free collectible for our community & day one supporters. 
            <br /><br />To make it as affordable and environmentally friendly as possible, 
            we chose to use the Polygon Network which minimizes the cost, energy, and carbon impact on the blockchain.
          </s.TextSummary>
        <s.SpacerSmall />

          </s.TextSummaryBox>
          </s.item2>

          <s.item3> */}
          {/* <s.ContainerButton
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{ backgroundColor: "none", padding: 0 }}
          >



            {Number(data.totalSupply) == 99999999 ? (
              <>
                <s.TextTitle style={{ textAlign: "center" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextHeader style={{ textAlign: "center" }}>
                  You can find the Soulection Community Gems on the secondary market @ {" "}
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={"https://opensea.io/collection/"}
                  >
                    https://www.opensea.io
                  </a>
                </s.TextHeader>

              </>
            ) : (
              <>
              
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                <s.Container 
                      ai={"center"} jc={"center"} fd={"column"}>
                    <StyledButton
                      id="click-me"
                      // id="btc"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                    <s.TextButton style={{ textAlign: "center", fontFamily: "JetSemiBold" }}>
                    CONNECT
                    </s.TextButton>
                    </StyledButton>
                    </s.Container>
                    {blockchain.errorMsg !== "" ? (
                      <>


                        <s.TextDescription style={{ textAlign: "center", fontFamily: "JetRegular"  }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container 
                      // id="btc"
                      ai={"center"} jc={"center"} fd={"row"}>
                    <StyledButton
                      id="click-me"
                      // id="btc"
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);
                        getData();
                      }}
                    >
                      {claimingNft ? 
                      <s.TextButton style={{ textAlign: "center", fontWeight: 600, fontFamily: "JetSemiBold" }}>
                      •••
                      </s.TextButton> : 
                      <s.TextButton style={{ textAlign: "center", fontWeight: 600, fontFamily: "JetSemiBold" }}>
                    MINT
                    <br />
                    </s.TextButton>
                      }
                    </StyledButton>
                  </s.Container>
                )}
              </>
            )}
        <s.ContainerFeedback>
          <s.TextFeedback style={{ textAlign: "center", fontFamily: "Bebas" }}>
                  {feedback} <br />
          </s.TextFeedback>
        </s.ContainerFeedback>
          </s.ContainerButton>   */}

          {/* </s.item3>

          <s.item4>
            
          <s.TextSubHead style={{ textAlign: "left", margin: 0, fontFamily: "JetSemiBold", textTransform: "none" }}>
                {/* <HighlightSpan>Specifications</HighlightSpan> <br /> */}
                {/* <SpanThin>COST:</SpanThin>&nbsp;<br/> <SubSpan>FREE</SubSpan><SmallSpan>[EXCL. POLYGON GAS FEES]</SmallSpan>
                <SpanThin>GEMS_MINTED:</SpanThin>&nbsp;<br /><SubSpan> {data.totalSupply}</SubSpan> <br />
                <SpanThin>MINTING_STARTS_IN:</SpanThin>
                    <br /> */}
                {/* <SpanThin>Mint_Dates: </SpanThin>
                   02/18/2022 - 02/25/2022 <br /> */}
                {/* <Countdown />
                <SpanThin>DETAILS:</SpanThin><br /><s.TextDetails>• Please make sure you are connected to the Polygon Network. <br />
                • The mint is open for 7 days to make sure everyone has a chance to get one.  
              <br />• If you need any technical assistance beyond our step by step guide, please join our discord 
              & and hit the web3 channel to reach out!</s.TextDetails>  */}

          {/* <s.TextDetails>
            <HighlightSpan3>Details:</HighlightSpan3> The mint is open for 7 days to make sure everyone has a chance to get theirs.  
              <br />If you need any technical assistance beyond our step by step guide, please join our discord 
              & and hit the web3 channel to reach out!
            </s.TextDetails> */}

                {/* <s.TextDescription>
                    <TextSpan2></TextSpan2>
                </s.TextDescription> */}

            {/* <SubSpan>+&nbsp;</SubSpan> */}
            {/* {/* <TextSpan>Produced by x<br /></TextSpan> */}
            {/* <TextSpan>Mint Dates: <br />02/18 - 02/25 <br /></TextSpan>  */}
            {/* <s.SpacerLarge />
            <s.SpacerLarge /> */}
             {/* </s.TextSubHead>
          </s.item4>
          
        </s.AngryGrid> */}

          {/* <s.SpacerLarge /> */}
          {/* <s.SpacerSmall /> */}




          <Routes>
          <Route path="/" exact element={<CardPage/>} />
          <Route path="/CG001" exact element={<CG001/>} />


          </Routes>

        </ResponsiveWrapper>
        {/* <s.SpacerLarge />
            <s.SpacerLarge />
            <s.SpacerLarge />  */}
        {/* <TopHeader> */}
                 
              {/* <LogoImg alt={"logo"} 
                src={i2}
                flex={1}
                fd={"row"}
                ai={"center"}
                jc={"center"} />  */}
          {/* </TopHeader> */}
        {/* <s.SpacerLarge /> */}


          {/* <s.TextHeader style={{ textAlign: "center", margin: 0, fontFamily: "Bebas" }}>
                Presents
          </s.TextHeader> */}
          {/* <s.TextHeaderSub style={{ textAlign: "center", margin: 0, fontFamily: "futura" }}>
                - A SOULECTION<SubSpan>+&nbsp;</SubSpan>
                    COLLECTIBLE - <br />
          </s.TextHeaderSub> */}

          <s.SpacerSmall />

        <s.GalleryContainer jc={"center"} ai={"center"} style={{  }}>
          
              {/* <CardGallery>
                <div class="container">
              <div class="card">
                <div class="box">
                  <div class="content">
                    <h2>01</h2>
                    
                    <h3>SOULECTION+ RADIO: UNTITLED 001</h3>
                    <p>teste teste teste teste teste teste teste teste</p>
                    <a id="c1" href="#">Go to Sound.xyz</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="card">
                <div class="box">
                  <div class="content">
                    <h2>02</h2>
                    
                    <h3>FREE COMMUNITY GEMS 001</h3>
                    <p>teste teste teste teste teste teste teste teste</p>
                    <a id="c2" href="#">Go to Mint Page</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="card">
                <div class="box">
                  <div class="content">
                    <h2>03</h2>
                    <h3>MONTE BOOKER: KOLORS AUDIO/VISUAL COLLECTIBLE</h3>
                    <p>teste teste teste teste teste teste teste teste</p>
                    <a id="c3" href="#">View</a>
                  </div>
                </div>
              </div>
            </div>
            </CardGallery> */}

          {/* <CardGallery>
            <s.TextSubHeadNote style={{ textAlign: "center", margin: 9, fontFamily: "JetLight", textTransform: "uppercase" }}>
              <a href="http://info.soulection.plus/" target="_blank" rel="noreferrer noopener">Click here for a step-by-step guide on how to mint this free digital collectible</a>
            </s.TextSubHeadNote>

          </CardGallery> */}
          </s.GalleryContainer>


          <s.BottomContainer>
          <CardInfo>
            <s.TextSubHeadNote style={{ textAlign: "center", margin: 9, fontFamily: "JetLight", textTransform: "uppercase" }}>
              <a href="http://info.soulection.plus/" target="_blank" rel="noreferrer noopener">Click here for our Web3 info & step-by-step minting guides</a>
            </s.TextSubHeadNote>

          </CardInfo>
          {/* <s.TextDescription style={{ textAlign: "center", fontFamily: "Argentum", fontSize: ".7rem" }}>
          We are holding back 20 digital collectibles<br />to distribute internally to the creatives who helped bring this project to life.
          </s.TextDescription> */}

          <FContainer>

                  {/* <AnimatedSocialIcon
                    brandName="twitch"
                    url="https://www.twitch.tv/soulection"
                    animation="bounce"
                    defaultColor="#D8D8D8"
                    hoverColor="#6441A5"
                    width="2em"
                    animationDuration={1.5}
                    style={{padding: '0'}}
                    /> */}
                <AnimatedSocialIcon
                    brandName="instagram"
                    url="https://www.instagram.com/soulection/"
                    animation="bounce"
                    defaultColor="#D8D8D8"
                    hoverColor="#8a3ab9"
                    width="2em"
                    animationDuration={1.5}
                    style={{padding: '0'}}
                    />
                  <AnimatedSocialIcon
                    brandName="discord"
                    url="https://discord.gg/soulection"
                    animation="bounce"
                    defaultColor="#D8D8D8"
                    hoverColor="#5865F2"
                    width="2em"
                    animationDuration={1.5}
                    style={{padding: '0'}}
                    />
                  <AnimatedSocialIcon
                    brandName="twitter"
                    url="https://twitter.com/soulection"
                    animation="bounce"
                    defaultColor="#D8D8D8"
                    hoverColor="#1DA1F2"
                    width="2em"
                    animationDuration={1.5}
                    style={{padding: '0'}}
                    />
          </FContainer>  
          </s.BottomContainer>

      </s.Container>
    </s.Screen>
    </Router>

    
    
  );
}

export default App;