import React from 'react';
import * as s from "../styles/globalStyles";
import { 
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	NavLink,
} from 'react-router-dom';
import styled from "styled-components";
import i15 from "../assets/images/soulectionradio001.JPG";

import VideoPlayer from "./VideoPlayer";
import Video from "./Video";
import Countdown from './Countdown';





export const Img = styled.img`
    width: 60%;
    height: 60%;
    margin-bottom: 5%;
    @media only screen and (max-width: 40em) { 
        width: 80%;
        height: 80%;
   
        
        } /* max-width 640px, mobile-only styles, use when QAing mobile issues */
    `;

export const HighlightGrid = styled.div`
      display: grid; 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   
      grid-auto-rows: minmax(min-content, max-content);
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr) );
      
      gap: 0px;
      height: 100%;
      // max-width: 80%;
      margin-bottom: 15%;
       position: relative;
       
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #CCCCC4 ;

  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.051),
  0px 0px 7.2px rgba(255, 255, 255, 0.073), 0px 0px 13.6px rgba(255, 255, 255, 0.09),
  0px 0px 24.3px rgba(255, 255, 255, 0.107), 0px 0px 45.5px rgba(255, 255, 255, 0.129),
  0px 0px 109px rgba(255, 255, 255, 0.18);
  animation: fadeUp 3s ease 0s 1 normal;
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translate3d(0,10%,0);
    }
    to {
      opacity: 1;
      transform: translate3d(0%,0,0);
    }
  }

  @media only screen and (max-width: 40em) { 
    margin-top: -7%;
    width: 83%;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   

  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    margin-top: -5%;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

  @media only screen and (min-width: 64.063em) and (max-width: 90em) { 
    // margin-top: -10%;
  }

  @media only screen and (min-width: 90.063em) and (max-width: 120em) 
    width: 90%;
    margin-top: -1%;    
   } /* min-width 1441px, xlarge screens */

   @media only screen and (min-width: 120.063em) {
    margin-top: 1%;
    margin-bottom: 8%;  
  }
      `;
     
export const HighlightPic = styled.div`
   
      // background-color: #DFFB7D; 
      grid-row-start: 3;
      grid-column-start: 1;
   
      grid-row-end: 5;
      grid-column-end: 3;
      `;
   
export const HighlightHead = styled.div`
   
      // background-color: #9A69EB; 
      grid-row-start: 1;
      grid-column-start: 1;
   
      grid-row-end: 2;
      grid-column-end: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      `;
   
export const HighlightSub = styled.div`
   
      // background-color: #999C8F; 
      grid-row-start: 2;
      grid-column-start: 1;
   
      grid-row-end: 3;
      grid-column-end: 3;
      @media only screen and (max-width: 40em) {
        margin-bottom: 4%;
        }
        @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
          height: 100%;
          // background: green;
          margin-top: -2%;
          }
      
          @media only screen and (min-width: 64.063em) and (max-width: 90em) {
            // padding: 30px 0px 10px 0px;
            // background: blue;
            margin-bottom: -2%;
            margin-top: -2%;
          } /* min-width 1025px, large screens */
      
          @media only screen and (min-width: 90.063em) { 

            // background: yellow;
            margin-bottom: 1%;
          } /* min-width 1441px, xlarge screens */
      
          @media only screen and (min-width: 120.063em) { 
            // background: red;
          }
      `;

  export const ImgContainer = styled.div`
      // display: flex;
      display: flex;
      // background-color: white;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      margin-top: -2.75%;
      // margin: -21% 0px 0px 0%;
      padding-top: 10px;
      // transform: translateY(-20%);
    // transform: translate(-99%, 40.7%);
      
      @media only screen and (max-width: 40em) { 
        // transform: translateX(-16%);
        padding: 00px 0px 00px 0px;
        height: 100%;
        // background: pink;
        }
        @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
        height: 100%;
        // background: green;
        margin-top: -2%;
        }
    
        @media only screen and (min-width: 64.063em) and (max-width: 90em) {
          padding: 30px 0px 10px 0px;
          // background: blue;
        } /* min-width 1025px, large screens */
    
        @media only screen and (min-width: 90.063em) { 
          padding: 00px 0px 00px 0px;
          margin: 0% 0px 0px 0%;
          // background: yellow;
          margin-top: -2%;
 
        } /* min-width 1441px, xlarge screens */
    
        @media only screen and (min-width: 120.063em) { 
          // background: red;
        }
    
      `;

export default function CardPage() {
  
  let videoSource =
  "https://soulmint.s3.us-west-1.amazonaws.com/untitled001.mp4";

    return (
        <s.GalleryContainer jc={"center"} ai={"center"} style={{  }}>
          
            <s.CardGallery>

              <HighlightGrid>
              <div class="content">     
    

                  <HighlightHead>
                    <s.Container>
                    <s.TextHeaderSub>
                  <h3>MONTE BOOKER: <br /> KOLORS AUDIO + VISUAL DIGITAL COLLECTIBLE</h3>
                  </s.TextHeaderSub>
                  </s.Container>
                  </HighlightHead>

                    <HighlightSub>
                      {/* <s.TextHeaderDescription>
                        03/17/2022 <br />11AM LA | 2PM NY <br />
                      
                    </s.TextHeaderDescription> */}
                    </HighlightSub>
                    </div>

                    <HighlightPic>
                <ImgContainer>    
                    
                <a href="https://montebooker.soulection.plus/KOLORS" target="_blank" rel="noreferrer noopener" style={{ border: "none" }}>

                  <Img alt={"logo"} 
              src={"https://kolorsimages.s3.us-west-1.amazonaws.com/kolors.gif"}
              style={{ width: "18rem", height: "18rem"}} />  </a>
              </ImgContainer>      
              
                </HighlightPic>
              {/* <Countdown /> */}
              {/* <a href="https://montebooker.soulection.plus" target="_blank" rel="noreferrer noopener" style={{ border: "none" }}>
                <s.TextHeaderDescription>Go to Mint Page</s.TextHeaderDescription>
                </a> */} 
                                                  

  
              </HighlightGrid>
       


            </s.CardGallery>
            <s.SpacerMedium />
            {/* <s.SpacerLarge /> */}



            <s.CardGallery>




            <div className="video-container">
            <div class="video-card">
              <div class="video-box">
                <div class="video-content">
                <div class="videoWrapper">
      {/* {videoSource && <VideoPlayer 
              src={videoSource}/>} */}
              
              <Video />
              <iframe width="750" height="410" src="https://www.youtube.com/embed/kH7_S4DgDU0?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </div>
            </div>
            </div>
            </div>
            </s.CardGallery>

            <s.SpacerLarge /> 
           <s.SpacerSmall />
          
        <s.CardGallery>
          <div class="container">
        <div class="card">
          <div class="box">
            <div class="content">
              {/* <h2>01</h2> */}
              <a href="https://opensea.io/collection/soulection" target="_blank" rel="noreferrer noopener" style={{ border: "none" }}>
              <Img alt={"logo"} 
              src={"https://soulmint.s3.us-west-1.amazonaws.com/soulectionradio001.JPG"}
              style={{ }} /></a>
              {/* <hr />
              <br /> */}
              <h3>SOULECTION+ RADIO: UNTITLED 001</h3>
              {/* <p>teste teste teste teste teste teste teste teste</p> */}
              <a id="c1" href="https://opensea.io/collection/soulection" target="_blank" rel="noreferrer noopener">Listen Free on OpenSea.io</a>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="container">
        <div class="card">
          <div class="box">
            <div class="content">
              <h2>03</h2>
              <Img alt={"logo"} 
              src={"https://kolorsimages.s3.us-west-1.amazonaws.com/0491.png"}
              style={{ }} /> 
              <h3>MONTE BOOKER: KOLORS COLLECTIBLE</h3>
              <p>teste teste teste teste teste teste teste teste</p>
              <a id="c1" href="" target="_blank" rel="noreferrer noopener">Live 3/17/2022</a>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container">
        <div class="card">
          <div class="box">
            <div class="content">
              {/* <h2>02</h2> */}
              <a id="c1" href="https://opensea.io/collection/community-gems" target="_blank" rel="noreferrer noopener" style={{ border: "none" }}>
							<Img alt={"logo"} 
              src={"https://soulmint.s3.us-west-1.amazonaws.com/lime.gif"}
              style={{ }} /> </a>
              {/* <hr /> */}
              <br />
              <h3>SOULECTION COMMUNITY GEMS</h3>
              {/* <p>teste teste teste teste teste teste teste teste</p> */}
              <a id="c1" href="https://opensea.io/collection/community-gems" target="_blank" rel="noreferrer noopener">Find on OpenSea.io</a>
         
              
              {/* <Link to="/CG001" exact className="nav-link" activeClassName="active-link">
							Go to Mint Page</Link> */}
                           
            </div>
          </div>
        </div>
      </div>
      </s.CardGallery>

    {/* <CardGallery>
      <s.TextSubHeadNote style={{ textAlign: "center", margin: 9, fontFamily: "JetLight", textTransform: "uppercase" }}>
        <a href="http://info.soulection.plus/" target="_blank" rel="noreferrer noopener">Click here for a step-by-step guide on how to mint this free digital collectible</a>
      </s.TextSubHeadNote>

    </CardGallery> */}
    </s.GalleryContainer>
    )
}