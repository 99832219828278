import React, {createRef, useEffect} from 'react';

import { applyPolyfills, defineCustomElements } 
         from '@deckdeckgo/youtube/dist/loader';

applyPolyfills().then(() => {
    defineCustomElements(window);
});

const Video = () => {

    const container = createRef();

    const videoObserver = new
        IntersectionObserver(onVideoIntersection, {
          rootMargin: '100px 0px',
          threshold: 0.25
        });

    useEffect(() => {
        if (window && 'IntersectionObserver' in window) {
            if (container && container.current) {
                videoObserver.observe(container.current);
            }
        } else {
            loadVideo();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container]);

    function onVideoIntersection(entries) {
        if (!entries || entries.length <= 0) {
            return;
        }

        if (entries[0].isIntersecting) {
            loadVideo();
            videoObserver.disconnect();
        }
    }

    async function loadVideo() {
        if (container && container.current) {
            container.current.lazyLoadContent();
        }
    }

    return (
        <div>

            <deckgo-youtube
                id="mobile-yt"
                ref={container} 
               src="https://www.youtube.com/embed/kH7_S4DgDU0?controls">
            </deckgo-youtube>
        </div>
    );
};

export default Video;