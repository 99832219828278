import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import "./styles/theme.css";



// import Header from "../components/Header";
// import Hero from "C:/Users/RTT/Documents/PROGRAMMING/WEB3/HASHLIPSBASE/nerdy_coder_clones_tutorial/example_nft_minter/src/components/Hero.js";
// import Mint from "../components/Mint";

ReactDOM.render(
  <Provider store={store}>
    {/* <Hero /> */}
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
